// ? This component is used to display the details of a single wine. It uses the location prop to get the selected wine from the previous page. 
// ? It also uses the LikeContext and NotesContext to handle the like button and notes. It also uses the wineriesAddress to display the address of the winery on the map.

import React, { useEffect, useState, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useStateValue } from '../context/StateProvider';
import { LikeContext } from "../context/LikeContext";
import NotesContext from '../context/NotesContext';
import { motion } from 'framer-motion'
import { v4 as uuidv4 } from 'uuid';
import wineImage from '../utils/wineImage';
import Addresses from '../components/Addresses';
import BackToTopButtom from '../components/BackToTopButtom';
import ChatPopup from '../components/ChatPopup';
import { FooterPage } from '../components/FooterPage';
import wineriesAddress from '../utils/WineriesAddress';

import { db } from "../firestore";
import { doc, serverTimestamp, addDoc, collection } from "firebase/firestore"; 
import { Rating } from 'react-simple-star-rating'
import { getAnalytics, logEvent } from "firebase/analytics";

const analytics = getAnalytics();

function SingleWine() {
    const [isLiked, setIsLiked] = useState(false);
    const [wine, setWine] = useState(null);
    const [showTextarea, setShowTextarea] = useState(false);
    const location = useLocation();
    const selectedWine = location.state ? location.state.selectedWine : null;
    const [isTextareaEmpty, setIsTextareaEmpty] = useState(true);
    const [isNotesSaved, setIsNotesSaved] = useState(false);
    const [isClicked, setIsClicked] = useState(false);
    const navigate = useNavigate();
    const { isAuthenticated, state } = useStateValue();
    const user = state.user;

    const [rating, setRating] = useState(0)
  
    // Catch Rating value
    const handleRating = (rate) => {
      setRating(rate)
      console.log(rate);
    }

    useEffect(() => {    
        setWine([]); // clear the wine list
        if (selectedWine) {
            setWine(selectedWine);

            // logEvent(analytics, 'select_item', {
            //     items:[{
            //         'item_id': selectedWine.id,
            //         'item_name':selectedWine.wineName,
            //         //'item_brand':selectedWine.wineryName
            //     }] 
            //   });

              logEvent(analytics, 'page_view', {
                page_title: selectedWine.wineryName + '_' + selectedWine.wineName
            });
        }
    }, [selectedWine]);
    // convert timestamp to string 
    const timestampToString = (timestamp) => {
        const date = new Date(timestamp.seconds * 1000);
        return date.toLocaleDateString('en-US');
    }

    // handle like button and create a unique id for each liked wine to share
    const { addLike, removeLike } = useContext(LikeContext);
    const handleLike = () => {
        if (isLiked) {
            removeLike(wine.id);
            setShowTextarea(false);
        } else {

            // // Get the current location
            // navigator.geolocation.getCurrentPosition(async position => {
            //     const { latitude, longitude } = position.coords;

            //     // Convert the coordinates to an address
            //     const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}`);
              
            //     const data = await response.json();
            //     if(data.results.length > 0) {
            //     const address = data.results[0].formatted_address;

            //     // Get the current time and day
            //     const now = new Date();
            //     const time = now.toLocaleTimeString('en-US');
            //     const day = now.toLocaleDateString('en-US');

            //     // Generate a unique ID for the liked wine
            //     wine.uuid = uuidv4();

            //     // Add the liked wine along with its unique ID, location, time, and day
            //     addLike({ ...wine, location: address, time, day });
            //     setShowTextarea(true);
            //     }
            // });
        }
        setIsLiked(!isLiked);
    };

    // handle notes change to save notes in the local storage
    const { notes, setNotes } = useContext(NotesContext);
    const handleNotesChange = (wineId) => (event) => {
        const note = event.target.value;
        setIsTextareaEmpty(note.trim() === '');
        if (note.trim() !== '') {
            setNotes({
                ...notes,
                [wineId]: note,
            });
        }
    };
    // handle submit notes to save notes in the local storage
    const handleNotesSubmit = (wineId) => () => {
        const note = notes && notes[wineId] ? notes[wineId] : '';
        if (note.trim() === '') {
            alert('Note is empty. It will not be saved.');
        } else {
            setShowTextarea(false);
            const saveNote = async () => {
                const docRef = doc(db, "userNotes", user.uid); 
                const colRef = collection(docRef, "Notes")
                await addDoc(colRef, {
                    dbid: selectedWine.id,
                    note: note,
                    date: serverTimestamp(),
                    wineId: selectedWine.loc,
                    imageSrc: selectedWine.imageSrc|| null,
                    vintage: selectedWine.vintage,
                    wineName: selectedWine.wineName,
                    wineryName: selectedWine.wineryName,
                    wineType: selectedWine.wineType,
                    rating: rating
                })
            };
            saveNote();
            setIsNotesSaved(true);
        }
    };
    // toggle textarea to show or hide the textarea for Taste Now!
    const toggleTextarea = () => {
        if (!isAuthenticated) {
            // Redirect the user to the login page
            navigate('/login');
        } else {
            setShowTextarea(!showTextarea);
            setIsClicked(!isClicked);
        }
    };

    // render the wine details
    if (!wine) { return <div className='text-center'>loading...</div> }

    return (
        <div className="container mx-auto px-4">
            <>
                {wine && (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <div key={wine.id} className="md:mt-12 overflow-hidden " >
                            <div className="flex flex-col md:flex-row">
                                <div className="md:flex-shrink-0 flex justify-center md:mt-10 md:gap-1">
                                <img src={wine.imageSrc || (wine.wineType && wineImage.default[wine.wineType.toLowerCase()])} alt={wine.wineName}
                                        className={` ${wine.wineryName === 'Ancestry' || wine.wineryName === 'Baer Winery' || wine.wineryName === 'Rooted Souls' ? 'label' : 'bottle'}`}
                                    />
                                </div>
                                <div className="mt-4 md:mt-6">
                                    <div className="flex flex-col  md:ml-0 tracking-wide text-2xl font-bold first-letter:uppercase gap-3">
                                        <div className='tex-2xl text-[#f01819] flex justify-center '>{wine.wineryName || null}</div>
                                        <div className="flex justify-center gap-3 tracking-wide text-2xl">{wine.vintage}, {wine.wineName}
                                            {/* <motion.div
                                                className="text-black cursor-pointer"
                                                onClick={handleLike}>
                                                {isLiked ?
                                                    <ion-icon name="heart-sharp"></ion-icon> :
                                                    <ion-icon name="heart-outline"></ion-icon>}
                                            </motion.div> */}
                                        </div>
                                    </div>
                                    <div className="mt-1 md:ml-16 text-[#050505] font-bold md:text-start text-center flex justify-center" > {wine.awards ? (wine.awards) : ''}</div>
                                    <div className="flex justify-center items-center">
                                        <button onClick={toggleTextarea} className={`${isClicked ? "bg-red-500":"bg-green-600"} rounded-full  w-28`}>
                                            {showTextarea ? 'Hide' : 'Taste Now!'}
                                        </button>
                                    </div>
                                    
                                    <div className="mt-4">
                                        {showTextarea && (
                                            <textarea
                                                aria-label="What did you think of this wine?"
                                                className="w-full md:ml-6 px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
                                                rows="4"
                                                value={notes && notes[wine.id] ? notes[wine.id] : ''}
                                                onChange={handleNotesChange(wine.id)}
                                                placeholder="What did you think of this wine?"
                                            />
                                        )}
                                        {showTextarea && <Rating
                                        SVGstyle={{ display: 'inline-block' }}
                                            allowFraction={false} // Allow to display half star
                                            onClick={handleRating}
                                        />}
                                        {!isTextareaEmpty && !isNotesSaved && (
                                            <button
                                                className="mt-2 px-4 py-2 font-bold ml-5 text-[#050505] bg-green-700 rounded-full hover:bg-green-700 focus:outline-none focus:shadow-outline"
                                                onClick={handleNotesSubmit(wine.id)}
                                            >
                                                Save
                                            </button>
                                        )}
                                    </div>
                                    <div className="flex flex-col md:flex-row  ">
                                        <div className="max-w-md mx-auto overflow-hidden md:max-w-3xl w-66 px-3 py-1 md:w-1/2">
                                            <p className="mt-5 md:ml-9 mb-4 text-[#050505] font-bold text-start text-xl">Wine Specs & Notes</p>
                                            {wine?.ava &&
                                                <>
                                                    <p className="mt-4 md:ml-9 text-[#050505] font-bold text-start">Ava:
                                                        <span className='text-[#050505]  font-normal m-2 first-letter:uppercase'>
                                                            {wine.ava}
                                                        </span>
                                                    </p>
                                                    <hr className='md:ml-9' />
                                                </>
                                            }
                                            {wine?.alcoholPercentage &&
                                                <>
                                                    <p className="mt-2 md:ml-9 text-[#050505] font-bold text-start">Alcohol:
                                                        <span className="text-[#050505] font-normal m-2">{wine.alcoholPercentage ? (wine.alcoholPercentage) : null}%</span>
                                                    </p>
                                                    <hr className='md:ml-9' />
                                                </>
                                            }
                                            {wine?.barrelMakeup &&
                                                <>
                                                    <p className="mt-2 md:ml-9 text-[#050505] font-bold text-start">Barrels Used:</p>
                                                    <ul className="text-[#050505] font-normal ml-4">
                                                        {Array.isArray(wine.barrelMakeup) ? wine.barrelMakeup.map((item, index) => {
                                                            if (typeof item === 'object') {
                                                                return (
                                                                    <li className='md:ml-5 -ml-4' key={index}>
                                                                        {`${item.barrel || ''} ${item.barrelType || ''} ${item.percentage}%`}
                                                                    </li>
                                                                );
                                                            }
                                                        }) : ''}
                                                    </ul>
                                                    <hr className='md:ml-9' />
                                                </>
                                            }
                                            {wine.bestYearsToDrink &&
                                                <>
                                                    <p className="mt-2 md:ml-9 text-[#050505] font-bold text-start">Best Year to Consumed:
                                                        <span className="text-[#050505] font-normal ml-4">{wine.bestYearsToDrink ? (wine.bestYearsToDrink) : null}</span>
                                                    </p>
                                                    <hr className='md:ml-9' />
                                                </>
                                            }
                                            {wine.bottlingDate &&
                                                <>
                                                    <p className="mt-2 md:ml-9 text-[#050505] font-bold text-start">Bottling Date:
                                                        <span className="text-[#050505] font-normal ml-4"> {typeof wine.bottlingDate === 'string' ? wine.bottlingDate : timestampToString(wine.bottlingDate)}</span>
                                                    </p>
                                                    <hr className='md:ml-9' />
                                                </>
                                            }
                                            {wine.casesProduced &&
                                                <>
                                                    <p className="mt-2 md:ml-9 text-[#050505] font-bold text-start">Case Production:
                                                        <span className="text-[#050505] font-normal ml-4">{wine.casesProduced ? (wine.casesProduced) : null}</span>
                                                    </p>
                                                    <hr className='md:ml-9' />
                                                </>
                                            }
                                            {wine.country &&
                                                <>
                                                    <p className="mt-2 md:ml-9 text-[#050505] font-bold text-start">Country:
                                                        <span className="text-[#050505] font-normal ml-4">{wine.country ? (wine.country) : null}</span>
                                                    </p>
                                                    <hr className='md:ml-9' />
                                                </>
                                            }
                                            {wine.varietal &&
                                                <>
                                                    <p className="mt-2 md:ml-9 text-[#050505] font-bold text-start">Varietal:</p>
                                                    <ul className="text-[#050505] text-sm font-light ml-4">
                                                        {Array.isArray(wine.varietal) ? wine.varietal.filter(item => item).map((item, index) =>
                                                            <li className='md:ml-5 -ml-4' key={index}>{`${item.varietal || ''}${item.grape ? ` ${item.grape}` : ''}${item.percentage ? `, ${item.percentage}%` : ''}`}</li>
                                                        ) : ''}
                                                    </ul>
                                                    <hr className='md:ml-9' />
                                                </>
                                            }
                                            {wine.vintage &&
                                                <>
                                                    <p className="mt-2 md:ml-9 text-[#050505] font-bold text-start">Vintage:
                                                        <span className="text-[#050505] font-normal ml-4">{wine.vintage ? (wine.vintage) : null}</span>
                                                    </p>
                                                    <hr className='md:ml-9' />
                                                </>
                                            }
                                            {/* {wine.residualSugar &&
                                                <>
                                                    <p className="mt-2 md:ml-9 text-[#050505] font-bold text-start">Residual Sugar:
                                                        <span className="text-[#050505] font-normal ml-4">{wine.residualSugar ? timestampToString(wine.residualSugar) : null}</span>
                                                    </p>
                                                    <hr className='md:ml-9' />
                                                </>
                                            } */}

                                            {wine.pH &&
                                                <>
                                                    <p className="mt-2 md:ml-9 text-[#050505] font-bold text-start">PH:
                                                        <span className="text-[#050505] font-normal ml-4">{wine.pH ? (wine.pH) : null}</span>
                                                    </p>
                                                    <hr className='md:ml-9' />
                                                </>
                                            }
                                            {wine.wineType &&
                                                <>
                                                    <p className="mt-4 md:ml-9 text-[#050505] font-bold text-start ">Type:
                                                        <span className='text-[#050505]  font-normal ml-4 first-letter:uppercase'>{wine.wineType ? wine.wineType : null}</span>
                                                    </p>
                                                    <hr className='md:ml-9' />
                                                </>
                                            }
                                            {wine.tastingNotes &&
                                                <>
                                                    <p className="mt-2 md:ml-9 text-[#050505] font-bold text-start">Tasting Notes:
                                                        <span className="text-[#050505] text-pretty text-md font-light ml-4 ">{wine.tastingNotes ? (wine.tastingNotes) : null}</span>
                                                    </p>
                                                    <hr className='md:ml-9' />
                                                </>
                                            }
                                            {wine.foodPairing &&
                                                <>
                                                    <p className="mt-2 md:ml-9 text-[#050505] font-bold text-start">Food you should try with this wine</p>
                                                    <p className="text-[#050505] text-md font-light mt-2 md:ml-9 first-letter:uppercase"> {wine.foodPairing ? (wine.foodPairing) : null}</p>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="grid md:grid-cols-2 grid-cols-1 gap-4">
                                <div className="col-span-1 m-3 px-3 py-1 ml-1 md:ml-10 lg:mt-12 lg:w-full lg:max-w-md lg:flex-shrink-0">
                                    <hr className='mt-10' />

                                    <p className="text-[#050505] font-bold text-start text-2xl"> <span className="font-mono underline-offset-4"> {wine.wineryName}</span></p>
                                    <p><strong>Location:</strong> <a
                                        className="text-sm underline hover:text-[#f50538] "
                                        href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(wineriesAddress[wine.wineryName])}`}
                                        target="_blank" rel="noopener noreferrer">
                                        {wineriesAddress[wine.wineryName] || 'Address not available'}
                                    </a>
                                    </p>
                                </div>
                                {/* <div className="col-span-1 max-w-md mx-auto overflow-hidden md:max-w-3xl m-5 w-66 px-3 py-1 md:w-1/2 mt-24">
                                    <div className='w-64 h-60 mr-8'>
                                        <Addresses width="500px" height="300px" address={wineriesAddress[wine.wineryName]} />
                                    </div>
                                </div> */}
                            </div>
                            <hr className='border border-gray-950 mt-14' />
                            <ChatPopup />
                            <FooterPage />
                            <BackToTopButtom />
                        </div>
                    </div>
                )
                }
            </>
        </div>
    )
}

export default SingleWine;