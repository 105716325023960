import React,{ useState, useEffect } from 'react';

// Create a context for the notes
const NotesContext = React.createContext({
    notes: {},
    setNotes: () => {},
    updateNotes: () => {},
  });

  export const NotesProvider = ({ children }) => {
    const [notes, setNotes] = useState(() => {
        const localData = localStorage.getItem('notes');
        return localData ? JSON.parse(localData) : {};
    });

    useEffect(() => {
        localStorage.setItem('notes', JSON.stringify(notes));
    }, [notes]);

    return (
      <NotesContext.Provider value={{ notes, setNotes}}>
          {children}
      </NotesContext.Provider>
  );

};

export default NotesContext;