import { getApp, getApps, initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";


const firebaseConfig = {
    apiKey: "AIzaSyBlJqfpPj9Xqbzwwk4zGmtwcvdXChJxMHs",
    authDomain: "winecapture.firebaseapp.com",
    databaseURL: "https://winecapture.firebaseio.com",
    projectId: "winecapture",
    storageBucket: "winecapture.appspot.com",
    messagingSenderId: "407458060255",
    appId: "1:407458060255:web:bf21fe0d976f02d375d66a",
    measurementId: "G-FXD4QV948N"
};

const app = getApps.length > 0 ? getApp() : initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);
// Initialize Analytics and get a reference to the service
const analytics = getAnalytics(app);

export  {app, db, storage, analytics}

