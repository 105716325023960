 const wineryAddresses = {
    'Adams Bench': '13590 NE Village Square Dr, Ste 1040 Woodinville, WA 98072',
    'Alexandria Nicole Cellars' : '19501 144th Ave NE. Woodinville, WA 98072',
    'Ambassador Wines of Washington' : '19501 144th Ave NE Suite C-400 Woodinville, WA 98072',
    'Ancestry' : '13590 NE Village Square Dr, Ste 1035 Woodinville, WA 98072 ',
    'Baer Winery' : '19501 144th Ave NE, F-100 Woodinville, WA 98072',
    'Barnard Griffin' : '17401 133rd Ave NE, C108 Woodinville WA 98072',
    'Bayernmoor Cellars' : '17140 135th Ave NE, Suite 1040, Woodinville, WA 98072',
    'Brian Carter Cellars' : '17140 135th Ave NE Woodinville, WA 98072 ',
    "L'Ecole 41" : '17401 133rd Ave NE Suite 1010 Woodinville, Washington 98072',
    'Montinore Estate & Landlines Estates' : '17401 133rd Ave NE Woodinville, WA 98072',
    'Rocky Pond Estate Winery' : '13475 NE Village Square Dr, Ste 1010 Woodinville, WA 98072 ',
    'Rooted Souls' : '13590 NE Village Square Dr Woodinville, WA 98072',
    'Truth Teller Winery' : '13590 NE Village Square Dr, Ste 1045 Woodinville, WA 98072',
    'Tsillan Cellars' : '13590 NE Village Square Dr, Ste 1045 Woodinville, WA 98072',
    'Valdemar Estate' : '3808 Rolling Hills Ln  Walla Walla, WA 99362'
   
};

export default wineryAddresses;

