// ? This component displays the list of wines for a selected winery. It also allows the user to filter the wines by type and current release. 
// ? The user can also like a winery and navigate to the single wine page by clicking on a wine card. 
// ? The user can also navigate to the chat page by clicking on the chat icon.
import React, { useState, useEffect, useContext } from 'react';
import { motion } from 'framer-motion';
import wineImage from '../utils/wineImage';
import Addresses from '../components/Addresses';
import { LikeContext } from '../context/LikeContext';
import { useLocation, useNavigate } from 'react-router-dom';
import ChatPopup from '../components/ChatPopup';
import BackToTopButtom from '../components/BackToTopButtom';
import SearchBar from '../components/SearchBar';
import { FooterPage } from '../components/FooterPage';
import { db } from "../firestore";
import { collection, getDocs, doc } from "firebase/firestore";
import { getAnalytics, logEvent } from "firebase/analytics";

const analytics = getAnalytics();

const Wines = () => {
    const [wineList, setWineList] = useState([]);
    const [loading, setLoading] = useState(false);
    const { addWineryLike, removeWineryLike } = useContext(LikeContext);
    const [isLiked, setIsLiked] = useState(false);
    const [selectedType, setSelectedType] = useState('currentlyTasting');
    const [selectedMenu, setSelectedMenu] = useState('');
    const location = useLocation();
    const navigate = useNavigate();
    const selectedWinery = location.state ? location.state.selectedWinery : null;
    const [selectedWineryInfo, setSelectedWineryInfo] = useState(null);
    const [address, setAddress] = useState('');
    const [likedWineries, setLikedWineries] = useState([]);
    const [wineMenus, setMenus] = useState([]);

    useEffect(() => {
        setLoading(true);
        setWineList([]); // clear the wine list
        setMenus([]); // clear the menu list
        if (selectedWinery) {
            const wineryInfo = selectedWinery.data.find(item => item.websiteURL);

            logEvent(analytics, 'page_view', {
                page_title: wineryInfo.wineryName
            });

            if (wineryInfo) {
                setSelectedWineryInfo(wineryInfo);
                // if (wineryInfo.address) {
                //     const { _lat, _long } = wineryInfo.address;
                //     axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${_lat},${_long}&key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}`)
                //         .then(response => {
                //             if (response.data.results[0]) {
                //                 setAddress(response.data.results[0].formatted_address);
                //             }
                //         })
                //         .catch(error => console.error(error));
                // }
                if(wineryInfo.tastingMenus != null && wineryInfo.tastingMenus.length > 0) {
                    setMenus(wineryInfo.tastingMenus);
                }   
            } if (selectedWinery.data && selectedWinery.data.length === 0) {
            }
            else {
                setWineList(selectedWinery.data);
            }
        }
        else{
            const fetchWineryInfo = async () => {
                try {
                    const docRef = doc(db, 'seattleMetroWineAreas', 'woodinvilleDowntownDistrict');
                    const subcollectionRef = collection(docRef, window.location.pathname.split('/')[2]);
                    const snapshot = await getDocs(subcollectionRef);
                    const collectionsData = snapshot.docs.map(doc => {
                        const data = doc.data();
                        return {
                            //id: doc.id,
                            ...data,
                            // wineryInfo: data.wineryInfo,
                            // loc: doc.ref.path
                        };
                        });
                    
                    const wineryInfo = collectionsData.find(item => item.websiteURL);
                    setSelectedWineryInfo(wineryInfo);
                    setWineList(collectionsData);
                    if(wineryInfo.tastingMenus != null && wineryInfo.tastingMenus.length > 0) {
                        setMenus(wineryInfo.tastingMenus);
                    }

                    logEvent(analytics, 'page_view', {
                        page_title: wineryInfo.wineryName
                    });

                } catch (error) {
                    console.error('Error fetching collections: ', error);
                }                
            };
            fetchWineryInfo();
        }
        setLoading(false);
    }, [selectedWinery]);

   

    // handle wine type change
    const handleTypeChange = (event) => {
        setSelectedType(event.target.value);
        setSelectedMenu(''); // reset selected Menu if user filters the wine
    };

    // handle like button
    const handleLike = () => {
        if (isLiked) {
            removeWineryLike(selectedWinery.id);
            setLikedWineries(likedWineries.filter(winery => winery.id !== selectedWinery.id));
        } else {
            addWineryLike({ id: selectedWinery.id, name: selectedWinery.wineryName });
            setLikedWineries([...likedWineries, selectedWinery]);
        }
        setIsLiked(!isLiked);
    };

    // filter the wine list by type and currentTasting , currentRelease, or all wines
    let filteredWineList = wineList;
    if (selectedType === 'currentlyTasting') {
        filteredWineList = wineList.filter(wine => wine.currentlyTasting);
    } else if (selectedType === 'currentRelease') {
        filteredWineList = wineList.filter(wine => wine.currentRelease);
    } else if (selectedType === 'all' || selectedType === '') {
        filteredWineList = wineList.filter(wine => wine.id !== 'wineryInfo');
    } else {
        filteredWineList = wineList.filter(wine => wine.wineType && wine.wineType.toLowerCase() === (selectedType && selectedType.toLowerCase()));
    }

    // handle menu change
    const handleMenuChange = (event) => {
        setSelectedMenu(event.target.value);
    };

    //filter the wines by the chosen menu
    if(wineMenus.length > 0 && selectedMenu !== ''){
        filteredWineList = wineList.filter(wine => wine.menuOptions && wine.menuOptions.includes(selectedMenu) && wine.currentlyTasting);
    }

    let displayMessage = '';
    if (filteredWineList.length === 0) {
        displayMessage = "No wines matched that filter criteria.";
    } else {
        displayMessage = "No wines available at this time.";
    }
    
    if(!selectedWineryInfo){
        displayMessage = "No winery information available at this time."
    }

    const LoadingCard = () => {
        return (
            <div className="max-w-md mx-auto border-t border-black shadow-md overflow-hidden md:max-w-6xl m-5">
                <div className="md:flex">
                    <div className="md:shrink-0">
                        <div className="h-96 w-24 mt-10 ml-28 mb-3 loading"></div>
                    </div>
                    <div className="m-12 px-5 py-4">
                        <div className="text first-letter:uppercase tracking-wide text-xl text-[#f50538] font-bold text-center loading" style={{ height: '20px', width: '70%' }}></div>
                        <div className="mt-2 text-[#f21505] font-bold md:text-start loading" style={{ height: '15px', width: '50%' }}></div>
                        <div className="mt-2 text-[#050505] font-bold md:text-start loading" style={{ height: '15px', width: '80%' }}></div>
                    </div>
                </div>
            </div>
        );
    };

    if (loading) { return <LoadingCard />; }
   
    return (
        <div className="max-w-md mx-auto rounded-lg  overflow-hidden md:max-w-7xl  md:mt-10">
            <div className=' items-start justify-center '>
                <div className="flex justify-center tracking-wide text-2xl  text-[#f50538] font-bold first-letter:uppercase gap-3">
                    {selectedWineryInfo && selectedWineryInfo.wineryName}
                    {/* <motion.div
                        className="text-black cursor-pointer"
                        onClick={handleLike}>
                        {isLiked ?
                            <ion-icon name="heart-sharp"></ion-icon> :
                            <ion-icon name="heart-outline"></ion-icon>}
                    </motion.div> */}
                </div>
                <div className='flex justify-center text-[#050505] font-sansref font-semibold mt-7 mb-10'>
                    {selectedWineryInfo && (
                        <p>
                            <span>{selectedWineryInfo.description.split('.').map((sentence, index) => (
                                <React.Fragment key={index}>
                                    {sentence}
                                    <br />
                                </React.Fragment>
                            ))}
                            </span>
                        </p>
                    )}
                </div>
                <p className='text-start text-[#050505] font-bold ml-2'>Filter by:</p>
                <div className="flex flex-col md:flex-row justify-evenly md:justify-start md:ml-0 md:space-x-4">
                    <select
                        value={selectedType}
                        onChange={handleTypeChange}
                        className="border border-[#050505] rounded-full p-1 m-2 text-[#050505] w-44">
                        <option value="currentlyTasting">Currently Tasting</option>
                        <option value="currentRelease">Current Release</option>
                        <option value="">All Wines</option>
                        <option value="White">White Wines</option>
                        <option value="Red">Red Wines</option>
                        <option value="Rose">Rose Wines</option>
                        <option value="Champagne">Champagne</option>
                        <option value="Sparkling rose">Sparkling Rose</option>
                        <option value="Sparkling white">Sparkling White</option>
                    </select>
                    {selectedType === "currentlyTasting" && wineMenus.length > 0 && (
                        <div className="flex items-center justify-between">
                            <select
                            className="border border-[#050505] rounded-full p-1 m-2 text-[#050505]"
                            onChange={handleMenuChange}>
                                <option value="">-- Choose a menu--</option>
                                {wineMenus.map((menu, index) => (
                                    <option key={index}>{menu}</option>
                                ))}
                            </select>
                            {/* <div className="md:hidden ">
                                <SearchBar />
                            </div> */}
                        </div>
                    )}
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-4 gap-4">
                    {displayMessage && filteredWineList.length === 0 ? (
                        <div className="flex w-screen">
                            <p className="text-[#f50538]  font-bold text-left text-2xl mt-8">{displayMessage}</p>
                        </div>
                    ) : (
                        filteredWineList.map((wine) =>
                            <div key={wine.id} onClick={() => navigate(`/singlewine/${wine.wineryName}/${wine.id}`, { state: { selectedWine: wine } })} className="cursor-pointer">
                                <div className="max-w-md mx-auto border rounded-xl border-slate-300  hover:shadow-xl overflow-hidden md:max-w-6xl m-5 ">
                                    <div className="flex md:grid-cols hover:scale-102 transition-transform duration-200">
                                        <div className="shrink-0">
                                            <img src={wine.imageSrc || (wine.wineType && wineImage.default[wine.wineType.toLowerCase()])}
                                                alt={wine.wineName}
                                                className={`hover:scale-110 transition-transform duration-400 ${wine.wineryName === 'Ancestry' || wine.wineryName === 'Baer Winery' || wine.wineryName === 'Rooted Souls' ? 'w-36 h-38 mt-10 md:ml-20 ml-28 mb-3' : 'w-28 h-96 mt-10 md:ml-24 ml-32 mb-3'}`}
                                            />
                                        </div>
                                    </div>
                                    <div className="m-5 ">
                                        <p className="mt-2 text-[#f50538] text-lg font-bold md:text-start"> {wine.vintage} {wine.wineName}</p>
                                        <p className=" text first-letter:uppercase tracking-wide  text-[#050505] font-serif text-start">
                                            {Array.isArray(wine.ava) ? wine.ava.map((item) =>
                                                item ? `${item.ava || ''}${item.percentage ? `, ${item.percentage}%` : ''}` : ''
                                            ).join(', ') : ''}
                                        </p>
                                        <p className="mt-2  text-[#050505] font-bold text-start">
                                            <span className="text-[#050505] font-normal ">
                                                {Array.isArray(wine.varietal) ? wine.varietal.map((item) =>
                                                    item ? `${item.varietal || ''}${item.grape ? ` ${item.grape}` : ''}${item.percentage ? `, ${item.percentage}%` : ''}` : ''
                                                ).filter(str => str.trim() !== '').join(', ') : ''}
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ))}
                </div>
            </div>
            <div className="grid md:grid-cols-2 grid-cols-1 gap-4">
                <div className="col-span-1 m-3 px-3 py-1 ml-1 md:ml-10 lg:mt-12 lg:w-full lg:max-w-md lg:flex-shrink-0">
                    <hr className='mt-10' />
                    <p className="text-[#050505] font-bold text-start text-2xl"> 
                        <span className="font-mono underline-offset-4">{selectedWineryInfo && selectedWineryInfo.wineryName} </span>
                    </p>
                    <p>
                        <strong>Website:</strong>{" "}
                        <a
                            className="text-sm font-mono underline-offset hover:text-[#f50538]"
                            href={selectedWineryInfo && selectedWineryInfo.websiteURL && (selectedWineryInfo.websiteURL.startsWith('http://') || selectedWineryInfo.websiteURL.startsWith('https://') ? selectedWineryInfo.websiteURL : `http://${selectedWineryInfo.websiteURL}`)}
                            target="_blank" rel="noopener noreferrer">
                            {selectedWineryInfo && selectedWineryInfo.websiteURL}
                        </a>
                    </p>
                    <p>
                        <strong>Tasting Room Location(s):</strong>{" "}
                        {selectedWineryInfo  && selectedWineryInfo.addresses.map(address => (
                            <a
                            className="text-sm underline hover:text-[#f50538] "
                            href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`}
                            target="_blank" rel="noopener noreferrer">
                               <br />
                           {address}
                            </a>                       
                        ))}
                    </p>
                </div>
                {/* <div className="col-span-1 max-w-md mx-auto overflow-hidden md:max-w-3xl m-5 w-66 px-3 py-1 md:w-1/2 mt-24">
                    <div className='w-64 h-60'>
                        <Addresses width="500px" height="300px" address={address} />
                    </div>
                </div> */}
            </div>
            <hr className='border border-gray-950 mt-3' />
            <ChatPopup />
            <FooterPage />
            <BackToTopButtom />
        </div>
    )

}

export default Wines;
