// ? This component is responsible for the chat popup that appears on the bottom right of the screen. 
// ? It uses the Chat UI Kit to create a chat interface and uses the OpenAI GPT-3 API to generate responses to user messages. 
// ? The chat popup is a button that when clicked opens a chat interface where the user can type messages and receive responses from the Wine Assistant. 
// ? The chat interface also displays a typing indicator when the Wine Assistant is generating a response. 

import { useState, useEffect, useRef } from 'react';
import { UilCommentChartLine, UilTimesCircle } from '@iconscout/react-unicons'
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import { motion } from 'framer-motion';
import { MainContainer, ChatContainer, MessageList, Message, MessageInput, TypingIndicator } from '@chatscope/chat-ui-kit-react';


const API_KEY = process.env.REACT_APP_OPENAI_KEY;
const ChatPopup = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [typing, setTyping] = useState(false);
  const [messages, setMessages] = useState([
    {
      message: 'Hi,I am your wine assistant. How can I help you?',
      sender: 'ChatBot'
    }
  ]);

  const messagesEndRef = useRef(null);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [messages]);

  const handleBotResponse = async (message) => {
    const newMessage = {
      message: message,
      sender: 'user',
      direction: 'outgoing'
    };

    const newMessages = [...messages, newMessage];
    setMessages(newMessages);

    setTyping(true);
    await processMessageToChatGPT(newMessages);
  }

  async function processMessageToChatGPT(chatMessages) {
    let apiMessages = chatMessages.map((messageObject) => {
      let role = '';
      if (messageObject.sender === 'ChatGPT') {
        role = 'assistant';
      } else {
        role = 'user';
      }
      return {
        role: role,
        content: messageObject.message
      }
    });

    const systemMessage = {
      role: 'system',
      content: 'Explain all the concepts like wine expert.'
    }

    const apiRequestBody = {
      'model': 'gpt-3.5-turbo',
      'messages': [
        systemMessage,
        ...apiMessages]
    }
    await fetch('https://api.openai.com/v1/chat/completions', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${API_KEY}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(apiRequestBody)
    }).then((data) => {
      return data.json();
    }).then((data) => {
      let botResponse = data.choices[0].message.content;

      // Check if the bot's response is related to wine
      if (!isResponseRelatedToWine(botResponse)) {
        // If not, set a default message
        botResponse = "Sorry, I can only assist with wine-related.";
      }

      setMessages(
        [...chatMessages, {
          message: botResponse,
          sender: 'ChatGPT',
        }]
      );
      setTyping(false);
    })
  }

  // Function to check if the bot's response is related to wine
  function isResponseRelatedToWine(response) {
    // Define keywords related to wine
    const wineKeywords = ['wine', 'grape', 'vineyard', 'tannin', 'sommelier'];

    // Check if any of the keywords is in the response
    for (let keyword of wineKeywords) {
      if (response.toLowerCase().includes(keyword)) {
        return true;
      }
    }

    return false;
  }

  return (
    <div className="fixed bottom-8 right-8 rounded-md">
      <motion.button 
      title='Chat with Wine Assistant'
      whileHover={{ scale: 1.3 }}
      whileTap={{ scale: 0.9 }}
      onClick={() => setIsOpen(!isOpen)} className="  rounded-full p-2 shadow-lg focus:outline-none  z-50 fixed bottom-3 right-2" >
        {isOpen ? <UilTimesCircle className=" text-black w-7 h-7  rounded-full"/> : <UilCommentChartLine className='text-[#04030b]  rounded-full w-9 h-9'/>}
      </motion.button>
      {isOpen && (
        <MainContainer className=" max-w-xs max-h-96  fixed bottom-0 right-12">
          <ChatContainer>
            <MessageList typingIndicator={typing ? <TypingIndicator content='ChatBot is Thinking' /> : null}>
              {messages.map((message, i) => {
                return <Message key={i} model={message} />
              })}
            </MessageList>
            <MessageInput
              placeholder="Type message here"
              onSend={handleBotResponse}
            />
          </ChatContainer>
        </MainContainer>
      )}
    </div>
  );
};

export default ChatPopup;