//? This is the About page view that will display the information about the app and the team that created it.
//? It will also display the team members and their roles and a brief description of their background and skills.


import React from 'react';
import BackToTopButtom from '../components/BackToTopButtom'
import ChatPopup from '../components/ChatPopup';
import VictorProfile from '../assets/images/team/VictorProfile.jpg'
import MatthewProfile from '../assets/images/team/MatthewProfile.webp'
import ShivaniProfile from '../assets/images/team/ShivaniProfile.jpg'
import WineLovers from '../assets/icons/wineLovers.png'
import { FooterPage } from '../components/FooterPage';
import {UilLinkedin } from '@iconscout/react-unicons';
import {motion} from 'framer-motion';
import { getAnalytics, logEvent } from "firebase/analytics";

const analytics = getAnalytics();

logEvent(analytics, 'page_view', {
    page_title: 'About'
  });

export const About = () => {
    return (
        <div className="overlay rounded-md">
            <div className="max-w-md mx-auto bg-transparent  shadow-sm overflow-hidden md:max-w-7xl mt-11 md:mt-4 ">
            <img src={WineLovers} alt="Wine Lovers" className="w-full h-[12vh] object-cover object-center mt-3 md:h-56" />
            <div className="px-4 py-5 sm:p-6 ">
                <h1 className="text-xl text-[#050505] font-bold flex justify-center">ABOUT </h1>
                <h1 className="text-4xl text-[#050505] font-bold mb-6 px-4 py-3 flex justify-center">IAmSOMM </h1>
                <p className="mb-4 text-center text-[#050505] text-lg line-clamp-6 px-5 md:text-start">
                IAmSOMM is an app designed for wine tasting rooms to help one learn more about the wine they are tasting.
                Use your smart phone camera to scan the QR code on the tasting menu or a wine bottle and learn more about the wine, the winery, and the winemaker.
                Sign in with your Google or Facebook account and take notes on the wines you enjoyed, get personalized recommendations of tasting rooms to visit
                next and to build a personal wine tasting journal that is available to you everywhere you go.
                Created by a team of wine lovers, engineers, and dreamers who want to make wine fun, easy to understand, and enjoy. 
                </p>
            </div>
                <h1 className="text-3xl text-[#050505] font-bold mb-6 px-4 py-3 flex justify-center">OUR TEAM </h1>
                <div className="flex flex-wrap justify-center">
                    <div className="w-full md:w-1/2 lg:w-1/3 p-6" >
                    <a href='https://www.linkedin.com/in/shivanicaplan/' target="_blank" rel="noopener noreferrer">
                        <div className="flex flex-col items-center justify-center bg-[#ffffff] rounded-lg shadow-lg shadow-[#630010]/50" >
                            <img className="rounded-full border-2 border-slate-300 h-36 w-36 mt-4" src={ShivaniProfile} alt="Profile" />
                            <div className="flex flex-col justify-center items-center">
                            <motion.h2
                                whileTap={{ scale: 0.9 }}
                                 className="font-bold text-[#050505] text-2xl mt-4 mb-2 flex items-center">Shivani Caplan <UilLinkedin className="ml-3"/></motion.h2>
                                <h3 className="font-semibold text-[#630010] text-xl mb-2">FOUNDER </h3>
                                <p className="text-2xs text-[#050505] text-start px-4 mb-4">
                                    I am a Software Engineer with a passion for wine and education. 
                                    I started exploring and tasting wines in 2008 after moving to Washington state. 
                                    I enjoy tasting wines with family and friends and learning about the stories behind each wine from the experts at the winery tasting rooms.
                                    After vising tasting rooms all across the country, I realized that I couldn't keep track of all the wineries I was vising, wines I was enjoying as well as wines I was bringing home to share with others.
                                    So I set out to create something to fill this need and IAmSOMM was born. I hope you enjoy using it as much as I enjoyed creating it. Cheers!
                                </p>
                            </div>
                        </div>
                    </a>
                    </div>
                    <div className="w-full md:w-1/2 lg:w-1/3 p-6" >
                    <a href='https://www.linkedin.com/in/victor-villagrana/' target="_blank" rel="noopener noreferrer">
                        <div className="flex flex-col items-center justify-center bg-[#ffffff] rounded-lg shadow-lg shadow-[#630010]/50">
                            <img className=" rounded-full h-36 w-36 mt-4" src={VictorProfile} alt="Profile" />
                            <div className="flex flex-col justify-center items-center">
                                <motion.h2
                                whileTap={{ scale: 0.9 }}
                                 className="font-bold text-[#050505] text-2xl mt-4 mb-2 flex items-center">Victor Villagrana <UilLinkedin className="ml-3"/></motion.h2>
                                <h3 className="font-semibold text-[#630010] text-xl mb-2 ">Software Engineer</h3> 
                                <p className="text-2xs text-[#050505] text-start px-4 mb-4">I am a Software Engineer passionate about front-end and back-end programming technologies.
                                Proficient in builder and innovator, turning ideas into reality. With a strong ability to learn new technologies quickly, I bring a critical eye to new projects.
                                With a combination of technical skills, problem-solving abilities, and a strong work ethic, I can deliver high-quality software solutions.
                                I'm committed to continuous learning through formal education, self-study, or practical experience.
                                This dedication keeps me at the edge of software engineering trends and best practices.</p>
                            </div>
                        </div>
                    </a>
                    </div>
                    <div className="w-full md:w-1/2 lg:w-1/3 p-6">
                        <a href='https://www.linkedin.com/in/matthew-mcl/' target="_blank" rel="noopener noreferrer">
                        <div className="flex flex-col items-center justify-center bg-[#ffffff] rounded-lg shadow-lg shadow-[#630010]/50">
                            <img className="rounded-full h-36 w-36 mt-4" src={MatthewProfile} alt="Profile" />
                            <div className="flex flex-col justify-center items-center"> 
                            <motion.h2
                                whileTap={{ scale: 0.9 }}
                                 className="font-bold text-[#050505] text-2xl mt-4 mb-2 flex items-center">Matthew Mclaughlin <UilLinkedin className="ml-3"/></motion.h2>
                                <h3 className="font-semibold text-[#630010] text-xl mb-2">Data Engineer & Business Intelligence</h3>
                                <p className="text-2xs text-[#050505] text-start px-4 mb-4">A Data Engineer with a background in the wine industry,
                                 I specialize as an App Data Engineer. Leveraging my expertise in Business Intelligence, 
                                 I develop user-friendly solutions to elevate consumer understanding and discoverability of wines they'll truly cherish. 
                                 Thriving in collaborative environments, I am always exploring innovative ways to translate data, boost customer engagement, and guide wineries' marketing efforts.</p>
                            </div>  
                        </div>
                        </a>
                    </div>
                </div>           
                <hr className='border border-gray-950 mt-6' />
                <ChatPopup />
               <FooterPage />
                <BackToTopButtom />
            </div>
        </div>
    )
}

export default About

