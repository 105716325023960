// ? This component is a search bar that allows users to search for wines by name or winery name. 
// ? It also displays a dropdown of wines that match the search query. 
// ? When a user clicks on a wine from the dropdown, they are taken to the winery page for that wine.
// ? The search bar is also responsive and will display a dropdown of wines that match the search query on mobile devices.

import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import wineImage from '../utils/wineImage';
import { useNavigate } from 'react-router-dom';
import { getDocs, collection } from 'firebase/firestore';
import { db } from '../firestore';
import { subcollectionNames } from '../context/subcollectionNames';

const SearchBar = () => {
    const [isFocused, setIsFocused] = useState(false);
    const dropdownRef = useRef(null);
    const [search, setSearch] = React.useState('');
    const [wineList, setWineList] = React.useState([]);
    const navigate = useNavigate();


    const getWines = async () => {
        const collections = subcollectionNames;
        let allWines = [];
        for (const collectionName of collections) {
            const querySnapshot = await getDocs(collection(db, 'seattleMetroWineAreas', 'woodinvilleDowntownDistrict', collectionName));
            const wines = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            allWines = [...allWines, ...wines];
        }

        setWineList(allWines);
    };

    useEffect(() => {
        getWines();

        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setSearch('');
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };

    }, [dropdownRef]);

    // handle the search input
    const handleTypeChange = (e) => {
        setSearch(e.target.value);
    };
    const displayedWines = wineList.filter(wine => {
        if (!wine.wineName && !wine.wineryName) {
            return false;
        }
        return search && ((wine.wineName && wine.wineName.toLowerCase().includes(search.toLowerCase())) || (wine.wineryName && wine.wineryName.toLowerCase().includes(search.toLowerCase())));
    });



    return (
        <div ref={dropdownRef} className="relative group px-3 py-2 flex justify-center ml-8 md:ml-6">
            <div className="relative">
                <input type="text"
                    className='text-[#0b0b0b] bg-[#ffffff] cursor-text rounded-xl px-2 border-solid border-2 border-black focus:outline-none focus:border-[#630010]'
                    value={search}
                    size='12'
                    onChange={handleTypeChange}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    placeholder="Search for wine. . ." />
            </div>
            {search && (
                <div className="absolute top-12 right-4 flex flex-col bg-[#ffffff] rounded-xl shadow-l md:w-96 w-full overflow-y-auto max-h-[270px] z-10">
                    {displayedWines.map(wine => (
                        <div key={wine.wineryName+wine.id} className="p-2 flex items-center">
                            <img src={wine.imageSrc || (wine.wineType && wineImage.default[wine.wineType.toLowerCase()])}
                                alt={wine.wineName} className="w-8 h-9 object-cover rounded mr-2" />
                            <div onClick={() => {
                                navigate(`/singlewine/${wine.wineryName}/${wine.id}`, { state: { selectedWine: wine } });
                                setSearch('');
                            }}
                                className="px-2 py-1 text-sm text-black hover:text-[#f50538] cursor-pointer  flex justify-between flex-grow"
                                title={wine.wineryName}>
                                <span>
                                    {wine.vintage} {wine.wineName ? wine.wineName.split(' ').map((word, index) => (
                                        <span key={index} className={word.toLowerCase().startsWith(search.toLowerCase()) ? 'opacity-100' : 'opacity-40'}> {word} </span>
                                    )) : wine['Wine Name']}
                                </span>
                                <span>
                                    {wine.wineryName ? wine.wineryName.split(' ').map((word, index) => (
                                        <span key={index} className={word.toLowerCase().startsWith(search.toLowerCase()) ? 'opacity-100' : 'opacity-40'}> {word} </span>
                                    )) : wine['Wine Name'] ? wine.wineName : ''}
                                </span>
                            </div>
                        </div>
                    ))}
                </div>
            )}
            {isFocused && (
                <div className="fixed inset-0 bg-black opacity-50 z-0"></div>
            )}
        </div>
    );
};

export default SearchBar;