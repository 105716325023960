import { fetchUser } from '../utils/fechtLocalData';

const userInfo = fetchUser();
;

export const initialState = {
    user: userInfo,
   
    
    
};
