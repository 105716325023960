// ? This component is used to display the details of the selected wine from the search results. 
// ? It will display the wine image, name, vintage, awards, and tasting notes.
// ? It will also display the like button and the notes textarea.

import React, { useEffect, useState, useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { LikeContext } from "../context/LikeContext";
import NotesContext from '../context/NotesContext';
import { motion } from 'framer-motion'
import { v4 as uuidv4 } from 'uuid';
import wineImage from '../utils/wineImage';
import BackToTopButtom from '../components/BackToTopButtom';
import ChatPopup from '../components/ChatPopup';
import { FooterPage } from '../components/FooterPage';
import { PropagateLoader } from 'react-spinners';


const SearchDetails = () => {
    const [isLiked, setIsLiked] = useState(false);
    const [showTextarea, setShowTextarea] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const [wine, setWine] = useState(null);
    const selectedWine = location.state ? location.state.selectedWine : null;
    const [isTextareaEmpty, setIsTextareaEmpty] = useState(true);
    const [isNotesSaved, setIsNotesSaved] = useState(false);
    const [Loading, setLoading] = useState(false);
    const [isClicked, setIsClicked] = useState(false);


    useEffect(() => {
        setLoading(true);
        setWine([]); // clear the wine list
        if (selectedWine && selectedWine.id) {
            setWine(selectedWine);

        }
        setLoading(false);
    }, [location, selectedWine]);

    // convert timestamp to string format
    const timestampToString = (timestamp) => {
        const date = new Date(timestamp.seconds * 1000);
        return date.toLocaleDateString('en-US');
    }

    // handle like button and create a unique id for each liked wine to share
    const { addLike, removeLike } = useContext(LikeContext);
    const handleLike = () => {
        if (isLiked) {
            removeLike(wine.id);
            setShowTextarea(false);
        } else {

            // Get the current location
            navigator.geolocation.getCurrentPosition(async position => {
                const { latitude, longitude } = position.coords;

                // Convert the coordinates to an address
                const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}`);
                const data = await response.json();
                const address = data.results[0].formatted_address;

                // Get the current time and day
                const now = new Date();
                const time = now.toLocaleTimeString('en-US');
                const day = now.toLocaleDateString('en-US');

                // Generate a unique ID for the liked wine
                wine.uuid = uuidv4();

                // Add the liked wine along with its unique ID, location, time, and day
                addLike({ ...wine, location: address, time, day });
                setShowTextarea(true);
            });
        }
        setIsLiked(!isLiked);
    };
    // handle notes change to save notes in NoteContext
    const { notes, setNotes } = useContext(NotesContext);
    const handleNotesChange = (wineId) => (event) => {
        const note = event.target.value;
        setIsTextareaEmpty(note.trim() === '');
        if (note.trim() !== '') {
            setNotes({
                ...notes,
                [wineId]: note,
            });
        }
    };
    // handle submit notes to save notes in NoteContext
    const handleNotesSubmit = (wineId) => () => {
        const note = notes && notes[wineId] ? notes[wineId] : '';
        if (note.trim() === '') {
            alert('Note is empty. It will not be saved.');
        } else {
            setShowTextarea(false);
            setIsNotesSaved(true);
        }
    };

    // toggle textarea to show or hide the textarea for Taste Now!
    const toggleTextarea = () => {
        setShowTextarea(!showTextarea);
        setIsClicked(!isClicked);
    };

    if (!wine) { return <h1 className='text-center'><PropagateLoader color={'#f50538'} loading={Loading} size={10} /></h1> }

    return (
        <div className="container mx-auto px-4 flex items-center justify-center min-h-screen">
            <>
                {wine && (

                    <div className="md:mt-12 shadow-lg overflow-hidden"
                        key={wine.id}>
                        <div className="flex flex-col md:flex-row">
                            <div className="md:flex-shrink-0 flex justify-center md:mt-10 cursor-pointer"
                             onClick={() => navigate(`/singlewine/${wine.wineryName}/${wine.id}`, { state: { selectedWine: wine } })}>
                                <img src={wineImage[wine.id] || wineImage.default[wine.wineType.toLowerCase()]} alt={wine.wineName}
                                    className={` ${wine.wineryName === 'Ancestry' || wine.wineryName === 'Baer Winery' || wine.wineryName === 'Rooted Souls' ? 'label' : 'bottle'}`}
                                />
                            </div>
                            <div className="mt-4 md:mt-8">
                                <div className="flex justify-center tracking-wide text-2xl  text-[#f50538] font-bold first-letter:uppercase gap-3">
                                    {wine.vintage}, {wine.wineName}
                                    <motion.div
                                        className="text-black cursor-pointer"
                                        onClick={handleLike}>
                                        {isLiked ?
                                            <ion-icon name="heart-sharp"></ion-icon> :
                                            <ion-icon name="heart-outline"></ion-icon>}
                                    </motion.div>
                                </div>
                                <div className="flex justify-center items-center">
                                    <button onClick={toggleTextarea} className={`${isClicked ? "bg-green-600" : "bg-red-500"} rounded-full  w-28`}>
                                        {showTextarea ? 'Hide' : 'Taste Now!'}
                                    </button>
                                </div>
                                <div className="mt-4">
                                    {showTextarea && (
                                        <textarea
                                            aria-label="What did you think of this wine?"
                                            className="w-full md:ml-6 px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
                                            rows="4"
                                            value={notes && notes[wine.id] ? notes[wine.id] : ''}
                                            onChange={handleNotesChange(wine.id)}
                                            placeholder="What did you think of this wine?"
                                        />
                                    )}
                                    {!isTextareaEmpty && !isNotesSaved && (
                                        <button
                                            className="mt-2 px-4 py-2 font-bold ml-5 text-[#050505] bg-red-500 rounded-full hover:bg-green-700 focus:outline-none focus:shadow-outline"
                                            onClick={handleNotesSubmit(wine.id)}
                                        >
                                            Save
                                        </button>
                                    )}
                                </div>
                                <p className="mt-2 text-[#050505] font-bold text-center" title='Wine Awards'> {wine.awards ? (wine.awards) : null}</p>
                                {wine.tastingNotes &&
                                    <>
                                        <p className="mt-2 md:ml-9 text-[#050505] font-bold text-start">Tasting Notes:</p>
                                        <p className="text-[#050505] text-start  text-md font-light mx-9 mt-5 cursor-pointer"
                                        onClick={() => navigate(`/singlewine/${wine.wineryName}/${wine.id}`, { state: { selectedWine: wine } })}>{wine.tastingNotes ? (wine.tastingNotes) : null}</p>

                                    </>
                                }
                            </div>
                        </div>
                        
                        <ChatPopup />
                        <FooterPage />
                        <BackToTopButtom />
                    </div>
                )
                }
            </>
            {/* <div className="fixed inset-x-0 bottom-0">
                <hr className='border border-gray-950 mt-6' />
                <ChatPopup />
                <FooterPage />
                <BackToTopButtom />
            </div> */}
        </div>
    )
}

export default SearchDetails