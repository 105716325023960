// ? This component displays the liked wines and wineries by the user and allows the user to remove them from the liked list.
// ? It also allows the user to share the liked wines and wineries on social media.
// ? also allows the user to add notes to the liked wines and wineries.
// ? It also allows the user to search for wines and wineries.

import { useContext, useState, useEffect } from 'react'
import { useStateValue } from '../context/StateProvider';
import { LikeContext } from '../context/LikeContext'
import NotesContext from '../context/NotesContext'
import { motion } from 'framer-motion'
import { Link, useNavigate } from 'react-router-dom'
import BackToTopButtom from '../components/BackToTopButtom'
import wineImage from '../utils/wineImage';
import ChatPopup from '../components/ChatPopup';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UilInstagram, UilTwitter, UilFacebookF } from '@iconscout/react-unicons'
import { FooterPage } from '../components/FooterPage'
import SearchBar from '../components/SearchBar'
import { db } from "../firestore";
import { collection, getDocs, doc, getDoc } from "firebase/firestore";
import { Rating } from 'react-simple-star-rating'
import { getAnalytics, logEvent } from "firebase/analytics";

const analytics = getAnalytics();

logEvent(analytics, 'page_view', {
    page_title: 'Notes'
  });
const LikeWines = () => {
  const { likedWines, removeLike, likedWineries, removeWineryLike} = useContext(LikeContext);
  const [loading, setLoading] = useState(false);
  const { notes, updateNotes } = useContext(NotesContext);
  const [textAreaValue, setTextAreaValue] = useState("");
  const navigate = useNavigate();
  const [wines, setWines] = useState([]);
  const {state } = useStateValue();
  const user = state.user;

  
  const handleClick = async (wineid,dbid) => {
    //fetch the wine details when clicked
      try {
        const docRef = doc(db, wineid);
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
          const wine = docSnap.data();
          navigate(`/singlewine/${wine.wineryName}/${dbid}`, { state: { selectedWine: wine } })

        } else {
          // docSnap.data() will be undefined in this case
          console.log("No such document!");
        }
    } catch (error) {
        console.error('Error fetching wine data: ', error);
  }
  };
  const fetchCollections = async () => {
    if(user.uid != null) {
      try {
        const docRef = doc(db, 'userNotes', user.uid);
        const subcollectionRef = collection(docRef, "Notes");

        await getDocs(subcollectionRef)
        .then((querySnapshot)=>{               
            const newData = querySnapshot.docs
                .map((doc) => ({...doc.data(), id:doc.id }));
            setWines(newData);                
            //console.log(wines, newData);
        }
      )
      } catch (error) {
          console.error('Error fetching collections: ', error);
    }
  }
};

  useEffect(()=>{
    fetchCollections();
    setLoading(true);
}, [])

  // convert timestamp to string  format
  const timestampToString = (timestamp) => {
    const date = new Date(timestamp.seconds * 1000);
    return date.toLocaleDateString('en-US');
  }
  // share wine function
  const shareWine = (wineName, wineryName) => {
    if (navigator.share) {
      navigator.share({
        title: 'My liked wine',
        text: `I liked ${wineName} from ${wineryName}`,
        url: window.location.href, // share the current URL
      })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing', error));
    } else {
      console.log('Share not supported on this browser, do it manually.');
    }
  };
  // if no liked wines, display a message
  if (wines.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <h1 className="text-2xl font-bold mb-4">No liked wines</h1>
        <Link to="/">
          <button className="px-4 py-2 bg-blue-500 text-white rounded">Go Home</button>
        </Link>
      </div>
    );
  }

  if (!loading) { return <h1 className='text-center text-[#f84444]'>Loading...</h1> }

  return (
    <div className="mt-10 container mx-auto px-4">
      <>

        {/* <div className="md:hidden mb-5 mr-6">
          <SearchBar />
        </div> */}
        {wines?.map((wine, i) => (
          <div key={i} >
            <div className=' rounded-lg shadow-md mt-5' onClick={() => handleClick(wine.wineId, wine.dbid)}>
              <div className="flex flex-col md:flex-row  md:mx-36">
                <div className="md:flex-shrink-0 flex justify-center mt-10 md:ml-5 cursor-pointer">
                  <img src={wine.imageSrc || wineImage.default[wine.wineType.toLowerCase()]} alt={wine.wineName}
                    className={` ${wine.wineryName === 'Ancestry' || wine.wineryName === 'Baer Winery' || wine.wineryName === 'Rooted Souls' ? 'label' : 'bottle'}`}
                  />
                </div>
                
                <div className="mt-4 md:mt-12 md:ml-4 ml-0  ">
                  <div className="ml-2 tracking-wide text-2xl text-[#f50538] font-bold first-letter:uppercase flex md:justify-center">{wine.vintage}, {wine.wineName}</div>
                  <div className="flex md:justify-center" >
                  {<Rating 
                    initialValue={wine.rating} /* Rating Props */ 
                    SVGstyle={{ display: 'inline-block' }}
                    readonly={true}
                    />}
                  </div>
                  <div className="">
                    <p className=" text-[#050505] text-lg first-line:font-bold md:ml-14 ml-4 mt-7">
                      Liked on: <span className='text-md font-light'>{wine.date.toDate().toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric'})}</span>
                    </p>
                    <p className=" p-4 text-[#f21505] font-bold md:text-start md:ml-11">Your Notes:</p>
                  </div>
                  <div className="md:ml-14 md:w-96 h-auto">
                    
                        <div key={wine} className="bg-[#ffffff] mt-2 p-4 border border-gray-200 rounded-lg shadow-lg flex flex-col">
                          <p className="text-[#050505] text-lg cursor-pointer"  onClick={() => handleClick(wine.wineId, wine.dbid)}>{wine.note}</p>
                          {/* <textarea
                            className="w-full h-20 border border-gray-300 rounded-lg p-2 mt-2 focus:outline-none focus:ring-2 focus:ring-[#f21505] focus:ring-opacity-50"
                            value={wine.note}
                            onChange={(e) => setTextAreaValue(e.target.value)}
                            onBlur={(e) => {
                              const newNotes = e.target.value;
                              updateNotes(wine.id, newNotes);
                              setTextAreaValue("");
                            }}
                          /> */}
                          <div className="mt-5">
                            <button
                              className="px-2 py-1 font-bold text-[#050505]  focus:outline-none focus:shadow-outline self-start"
                              onClick={() => {
                                if (navigator.share) {
                                  navigator.share({
                                    title: `Check out this wine: ${wine.wineName}`,
                                    text: `I found this great wine named ${wine.wineName} from ${wine.wineryName}. Check it out!`,
                                    url: window.location.origin + `/singlewine/${wine.wineryName}/${wine.id}`,
                                  })
                                    .then(() => console.log('Successful share'))
                                    .catch((error) => console.log('Error sharing', error));
                                } else {
                                  alert('Your browser does not support the Web Share API');
                                }
                              }}
                            >
                              <motion.p
                                whileHover={{ scale: 1.4 }}
                                whileTap={{ scale: 0.9 }}
                              >Share</motion.p>
                            </button>
                            <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
                            <a href={`https://instagram.com/insta?text=I found this great wine named: ${wine.wineName} from ${wine.wineryName}&url=${encodeURIComponent(window.location.origin + `/singlewine/${wine.wineryName}/${wine.id}`)}`}
                              target="_blank"
                              rel="noopener noreferrer">
                              <button>
                                <motion.p
                                  whileHover={{ scale: 1.4 }}
                                  whileTap={{ scale: 0.9 }}
                                  title='Share on Instagram'
                                  className="px-2 py-1 font-bold text-[#050505]  focus:outline-none focus:shadow-outline self-end"
                                >
                                  <UilInstagram color="#000000" />
                                </motion.p>
                              </button>
                            </a>
                            <a href={`https://www.facebook.com/text=I found this great wine named: ${wine.wineName} from ${wine.wineryName}&url=${encodeURIComponent(window.location.origin + `/singlewine/${wine.wineryName}/${wine.id}`)}`}
                              target="_blank"
                              rel="noopener noreferrer">
                              <button>
                                <motion.p
                                  whileHover={{ scale: 1.4 }}
                                  whileTap={{ scale: 0.9 }}
                                  title='Share on Facebook'
                                  className="px-2 py-1 font-bold text-[#050505]  focus:outline-none focus:shadow-outline self-end"
                                >
                                  <UilFacebookF color="#000000" />
                                </motion.p>
                              </button>
                            </a>
                            <a href={`https://twitter.com/tweet?text=I found this great wine named: ${wine.wineName} from ${wine.wineryName}&url=${encodeURIComponent(window.location.origin + `/singlewine/${wine.wineryName}/${wine.id}`)}`}
                              target="_blank"
                              rel="noopener noreferrer">
                              <button>
                                <motion.p
                                  whileHover={{ scale: 1.4 }}
                                  whileTap={{ scale: 0.9 }}
                                  title='Share on Twitter'
                                  className="px-2 py-1 font-bold text-[#050505]  focus:outline-none focus:shadow-outline self-end"
                                >
                                  <UilTwitter color="#000000" />
                                </motion.p>
                              </button>
                            </a>
                          </div>
                        </div>
                  </div>
                </div>
              </div>
            </div>
            
            {/*  TODO uncomment after remove functionality is implemented. 
            <div className="flex justify-end md:flex-row gap-4 mt-5">
              <button
                className="px-4 py-2  text-[#050505] hover:text-[#fa564b]  rounded-md border border-[#050505] md:w-15 w-40 h-10 mr-3"
                onClick={() => removeLike(wine.id)}
              >
                Remove Favorites
              </button>
            </div> */}
            {/* <div className="" onClinck="">
              <div className="bg-[#ffffff] mt-2 p-4 border border-gray-200 rounded-lg shadow-lg flex flex-col">
                <p className="flex justify-center text-[#050505] font-bold text-2xl mb-5 ">Your Liked Wineries</p>
                <div className="">
                  <ul className="list-none">
                    {likedWineries.map((winery) => (
                      <li key={winery.id} className="mb-4 flex justify-evenly items-center ">
                          <Link to={`/winery/${winery.id}`}>
                            <p className="text-[#050505] text-lg">{wine.wineryName}</p>
                          </Link>
                        <div>
                          <button
                            className="md:px-4 md:py-2 text-[#050505] hover:text-[#fa564b] w-full sm:w-auto mt-2 sm:mt-0"
                            onClick={() => removeWineryLike(winery.id)}
                          >
                            Remove
                          </button>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div> */}
          </div>
        ))}
        <hr className='border border-gray-950 mt-8' />
        <ChatPopup />
        <FooterPage />
        <BackToTopButtom />
      </>
    </div>
  )
}

export default LikeWines;