import React from "react";
import { useState, useEffect} from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { LikeProvider } from "./context/LikeContext";
import NotesContext from "./context/NotesContext";
import Navbar from './components/Navbar';
import MainPage from "./components/MainPage";
import Login from "./components/Login";
import Home from '../src/views/Home';
import About from '../src/views/About';
import Wines from './views/Wines';
import SingleWine from "./views/SingleWine";
import LikeWines from "./views/LikeWines";
import WineTypePage from "./views/WineTypePage";
import SearchDetails from "./views/SearchDetails";
import { AnimatePresence } from "framer-motion";
import TermsAndConditions from "./views/TermsAndConditions";
import PrivacyPolicy from "./views/PrivacyPolicy";

import "./App.css";

function App() {
  const [user, setUser] = useState(null);
  const [notes, setNotes] = useState(() => {
  const localData = localStorage.getItem('notes');
  return localData ? JSON.parse(localData) : '';
  });

  useEffect(() => {
    localStorage.setItem('notes', JSON.stringify(notes));
  }, [notes]);

  const updateNotes = (id, newNotes) => {
    setNotes(prevNotes => {
      return { ...prevNotes, [id]: newNotes };
    });
  };
  
  return (
    <AnimatePresence>
      <div className="w-screen h-full flex flex-col bg-[#ffffff] bg-cover" >
        <LikeProvider>
        <NotesContext.Provider value={{ notes, setNotes, updateNotes}}>
          <Router>
            <Navbar user={user} setUser={setUser} />
            <div className="mt-14 md:mt-24 px-4 md:px-16 py-4 w-full">
              <Routes>
                <Route path="/" element={<MainPage />} />
                <Route path="/home" element={<Home />} />
                <Route path="/login" element={<Login />} />
                <Route path="/about" element={<About />} />
                <Route path="/winery/:wineryName" element={<Wines />} />
                <Route path="/favorites" element={<LikeWines />} />
                <Route path="/singlewine/:wineryName/:id" element={<SingleWine/>} />
                <Route path="/winery/:wineryId/:id" element={<SearchDetails />} />
                <Route path="/wine/type/:wineType" element={<WineTypePage />} />
                <Route path="/TermsAndConditions" element={<TermsAndConditions />} />
                <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
              </Routes>
            </div>
          </Router>
          </NotesContext.Provider>
        </LikeProvider>
      </div>
    </AnimatePresence>
  );
}

export default App;

