import React, {createContext, useContext, useReducer, useState, useEffect} from "react";

export const StateContext = createContext();

export const StateProvider = ({reducer, initialState, children}) => {
    const [userName, setUserName] = useState('');
    const [isAuthenticated, setIsAuthenticated] = useState(() => {
        const savedAuthState = window.localStorage.getItem('isAuthenticated');
        return savedAuthState !== null ? JSON.parse(savedAuthState) : false;
    });
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        window.localStorage.setItem('isAuthenticated', JSON.stringify(isAuthenticated));
    }, [isAuthenticated]);

    return (
        <StateContext.Provider value={{ state, dispatch, userName, setUserName, isAuthenticated, setIsAuthenticated }}>
            {children}
        </StateContext.Provider>
    );
};

export const useStateValue = () => useContext(StateContext);