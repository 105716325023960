const renameWinery = (originalWineryName) => {
    const wineryNameMapping = {
        'adamsBench' : 'Adams Bench',
        'alexandraiNicoleCellars' : 'Alexandria Nicole Cellars',
        'ambassador' : 'Ambassador',
        'ancestryCellars' : 'Ancestry Cellars',
        'baerWinery' : 'Baer Winery',
        'barnardGriffin' : 'Barnard Griffin',
        'bayernmoorCellars' : 'Bayernmoor Cellars',
        'brianCarterCellars' : 'Brian Carter Cellars',
        'lecole' : "L'Ecole 41",
        'montinoreEstateAndLandlinesEstates' : 'Montinore Estate & Landlines Estates',
        'rockyPondEstateWinery' : 'Rocky Pond Estate Winery',
        'rootedSouls' : 'Rooted Souls',
        'truthTellers' : 'Truth Teller',
        'tsillanCellars' : 'Tsillan Cellars',
        'valdemarEstate' : 'Valdemar Estate'
    };
    return wineryNameMapping[originalWineryName] || originalWineryName;
}

export default renameWinery;