// wineImages.js
// ? import all the images here and export them as an object
// ?--------DEFAULT IMAGES--------
import defaultChanpagne from '../assets/icons/defaultChampagne.png';
import defaultRed from '../assets/icons/defaultRed.png';
import defaultRose from '../assets/icons/defaultRose.png';
import defaultWhite from '../assets/icons/defaultWhite.png';

const wineImage = {
    'default': {
        'red': defaultRed,
        'rose': defaultRose,
        'white': defaultWhite,
        'champagne': defaultChanpagne,
        'sparkling rose': defaultRose,
        'sparkling white': defaultWhite,
    }
};

export default wineImage;
