// ? This is the main page of the app, it will contain the main view of the app
import React from 'react'
import Home from '../views/Home'


const MainPage = () => {
  return (
    <div className='w-full h-auto flex flex-col items-center justify-center '>
      <Home />
    </div>
  )
}

export default MainPage