// subcollectionNames.js
export const subcollectionNames = [
    'adamsBench',
    'alexandraiNicoleCellars',
    'ambassador',
    'ancestryCellars',
    'baerWinery',
    'barnardGriffin',
    // 'bayernmoorCellars',
    'brianCarterCellars',
    'lecole',
    // 'montinoreEstateAndLandlinesEstates',
    'rockyPondEstateWinery',
    'rootedSouls',
    //'truthTellers',
    'tsillanCellars',
    // 'valdemarEstate'
];