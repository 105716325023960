// ? This component is used to login the user using google, facebook and apple
import React, { useState } from "react";
import { app } from '../firestore';
import { useNavigate } from 'react-router-dom';
import { useStateValue } from "../context/StateProvider";
import { actionType } from "../context/reducer";
import { UilFacebookF, UilGoogle, UilApple, UilTwitter } from '@iconscout/react-unicons'
import FooterPage from "./FooterPage";
import { getAuth, signInWithPopup, GoogleAuthProvider, FacebookAuthProvider, OAuthProvider, TwitterAuthProvider } from 'firebase/auth';
import { getAnalytics, logEvent } from "firebase/analytics";

const analytics = getAnalytics();


const Login = () => {
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const {dispatch, setUserName, setIsAuthenticated, isAuthenticated } = useStateValue();
    

    //? 1. Create a reference to the firebase auth provider
    const firebaseAuth = getAuth(app);
    const provider = new GoogleAuthProvider();
    const providerFacebook = new FacebookAuthProvider();
    const providerTwitter = new TwitterAuthProvider();
    const providerApple = new OAuthProvider('apple.com');

    //? 2. Create a function to login with google
    const handleGoogleLogin = () => {
        signInWithPopup(firebaseAuth, provider)
            .then((result) => {
                setUser(result.user);
                setUserName(result.user.displayName);
                setIsAuthenticated(true);
                dispatch({
                    // type: actionType.SET_USER,
                    // user: {
                    //     photoURL: result.user.photoURL
                    // }
                });
                localStorage.setItem('user', JSON.stringify(result.user));
                //navigate('/');
                navigate(-1); // return user to the page that redirected them to login page.
            }).catch((error) => {
                console.log(error);
            });

            logEvent(analytics, 'login',{method:'Google'});
    }

    //? 3. Create a function to login with facebook
    const handleFacebookLogin = () => {
        signInWithPopup(firebaseAuth, providerFacebook)
            .then((result) => {
                setUser(result.user);
                setUserName(result.user.displayName);
                setIsAuthenticated(true);
                dispatch({
                    type: actionType.SET_USER,
                    user: {
                        photoURL: result.user.photoURL
                    }
                });
                localStorage.setItem('user', JSON.stringify(result.user));
                navigate(-1); // return user to the page that redirected them to login page
            }).catch((error) => {
                console.log(error);
            });
            logEvent(analytics, 'login',{method:'Facebook'});

    }

    const handleTwitterLogin = () => {
        signInWithPopup(firebaseAuth, providerTwitter)
        .then((result) => {
                // This gives you a the Twitter OAuth 1.0 Access Token and Secret.
                // You can use these server side with your app's credentials to access the Twitter API.
                // const credential = TwitterAuthProvider.credentialFromResult(result);
                // const token = credential.accessToken;
                // const secret = credential.secret;
            
                // The signed-in user info.
                const user = result.user;
                setUser(user);
                setUserName(user.displayName);
                setIsAuthenticated(true);
                localStorage.setItem('user', JSON.stringify(user));
                navigate(-1); // return user to the page that redirected them to login page.
            }).catch((error) => {
                console.log(error);
            });
            logEvent(analytics, 'login',{method:'Twitter'});

    }

    //? 4. Create a function to login with apple
    const handleAppleLogin = () => {
        const auth = getAuth();
        signInWithPopup(auth, providerApple)
            .then((result) => {
                setUser(result.user);
                setUserName(result.user.displayName);
                dispatch({
                    type: actionType.SET_USER,
                    user: {
                        photoURL: result.user.photoURL
                    }
                });
                localStorage.setItem('user', JSON.stringify(result.user));
                navigate(-1); // return user to the page that redirected them to login page.
            }).catch((error) => {
                console.log(error);
            });
            logEvent(analytics, 'login',{method:'Apple'});

    }

    return (
        <>

            <div className="flex items-center justify-center h-full ">
                <div className="flex flex-col bg-transparent p-20 rounded-lg shadow-2xl space-y-4 w-96">
               {/* <h2 className="font-bold text-3xl text-center text-[#050505] ">Welcome Back</h2> */}
                        <>
                            <p className="text-center text-[#050505] text-lg">Login to continue</p>
                            <button
                                onClick={handleGoogleLogin}
                                className="py-2 px-4 bg-red-500 hover:bg-red-700 focus:ring-red-400 focus:ring-offset-blue-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg flex justify-center items-center">
                                <UilGoogle className='mr-2' /> Login with Google
                            </button>
                            <button
                                onClick={handleTwitterLogin}
                                className="py-2 px-4 bg-blue-500 hover:bg-blue-700 focus:ring-red-400 focus:ring-offset-blue-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg flex justify-center items-center">
                                <UilTwitter className='mr-2' /> Login with Twitter
                            </button>
                            {/* <button
                                disabled={true}
                                onclick={handlefacebooklogin}
                                classname="py-2 px-4 bg-blue-500 hover:bg-blue-700 focus:ring-blue-400 focus:ring-offset-blue-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg flex justify-center items-center">
                                <uilfacebookf classname='mr-2' /> login with facebook
                            </button> */}
                            {/* <button
                                onClick={handleAppleLogin}
                                className="py-2 px-4 bg-black hover:bg-gray-600 focus:ring-gray-400 focus:ring-offset-blue-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg flex justify-center items-center">
                                <UilApple className='mr-2' /> Login with Apple
                            </button> */}
                        </>
                    {/* )} */}
                    <div className="text-center"><FooterPage/></div>
                </div>
            </div>
        </>
    )
};

export default Login;