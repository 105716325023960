// ? This component is the navigation bar for the website. 
// ? It is used to navigate to different pages of the website. It also contains the search bar and the user's avatar. 
// ? It is responsive and changes its layout based on the screen size. It also contains the dropdown menu for the wineries.

import React, { useState, useEffect, useRef } from 'react'
import { motion } from 'framer-motion'
import { UilAngleDown, UilBars, UilTimes } from '@iconscout/react-unicons'
import PropagateLoader from "react-spinners/PropagateLoader";
import Avatar from '../assets/icons/avatar.png'
import DarklogoNew from '../assets/icons/darkLogoNew.png'
import { useStateValue } from '../context/StateProvider';
import { db } from "../firestore";
import { collection, getDocs, doc } from "firebase/firestore";
import { getAuth, browserSessionPersistence, setPersistence } from 'firebase/auth';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import SearchBar from '../components/SearchBar';
import renameWinery from '../utils/renameWinery'
import { subcollectionNames } from '../context/subcollectionNames';





const Navbar = ({ cart, setCart }) => {
    const navigate = useNavigate();
    const { state, dispatch, userName, setIsAuthenticated } = useStateValue();
    const user = state.user;
    const [isOpen, setIsOpen] = useState(false);
    const [dropDown, setDropDown] = useState(false);
    const dropDownRef = useRef(null);
    const menuRef = useRef(null);
    const location = useLocation();
    const [selectedWinery, setSelectedWinery] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [collections, setCollections] = useState([]);
    const [Loading, setLoading] = useState(false);
    // const totalQuantity = cart.reduce((total, item) => total + item.quantity, []);
    // const [isCartOpen, setIsCartOpen] = useState(false);
    useEffect(() => {
        const fetchCollections = async () => {
            setLoading(true);
            try {
                setCollections([]); // Clear the collections state
                const docRef = doc(db, 'seattleMetroWineAreas', 'woodinvilleDowntownDistrict');
                let newCollections = [];
                for (const name of subcollectionNames) {
                    const subcollectionRef = collection(docRef, name);
                    const snapshot = await getDocs(subcollectionRef);
                    const collectionsData = snapshot.docs.map(doc => {
                        const data = doc.data();
                        return {
                          id: doc.id,
                          ...data,
                          wineryInfo: data.wineryInfo,
                          loc: doc.ref.path
                        };
                      });
                    newCollections.push({ name, data: collectionsData });
                }

                setCollections(newCollections);
            } catch (error) {
                console.error('Error fetching collections: ', error);
            }
            
            setLoading(false);
        
        };
        fetchCollections();

        function handleClickOutside(event) {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsOpen(false);
                setDropDown(false);
            }
        }
        // Bind the event listener from welcome back popup
        if (user) {
            setShowPopup(true);
            setTimeout(() => {
                setShowPopup(false);
            }, 3000);
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };

    }, [selectedWinery, menuRef, user]);


    // log out function
    const logOut = async () => {
        const auth = getAuth();
        try {
            await setPersistence(auth, browserSessionPersistence);
            await auth.signOut();
            dispatch({
                type: 'SET_USER',
                user: null,
            });
            setIsOpen(false);
            setIsAuthenticated(false);
            navigate('/');
        } catch (error) {
            console.log('Error signing out:', error);
        }
    };
    const handleWinerySelect = (winery) => {
        if (winery && winery.name) {
            setSelectedWinery(winery);
            // Use JavaScript's built-in function encodeURIComponent to ensure the winery name is URL-safe
            let wineryName = encodeURIComponent(winery.name);
            navigate(`/winery/${wineryName}`, { state: { selectedWinery: winery } });
            setDropDown(false);
            setIsOpen(false);
        } else {
            console.error('Winery object is missing necessary properties');
        }
    };

 

    // const handleCartClick = () => {
    //     setIsCartOpen(!isCartOpen);
    // };
    // this is the navbar component for a desktop view
    return (
        <div className='bg-[#f3f1f1] text-[#050505] shadow-xl  px-0 md:p-2 md:px-12  w-full h-24 '>
            <div className="hidden md:flex w-full h-full items-center justify-between">
                <div className="flex items-center">
                <Link to={'/'}>
                        <img src={DarklogoNew} alt="logo" className='w-64 h-44 hover:opacity-90' />
                    </Link>
                </div>
                <div className="flex items-center gap-2 ">
                    <div>
                        <div className="flex items-center justify-center font-semibold">
                            <p className="relative group px-3 py-2">
                                <button className="hover:opacity-70 hover:text-[#050505] cursor-poiter text-xl">
                                    <Link to={'/home'}>Home</Link>
                                </button>
                            </p>
                            <div className="relative group px-6 py-2">
                                <p className="hover:opacity-70 hover:text-[#050505] cursor-default text-xl">Wineries</p>
                                <div
                                    className="absolute top-0 -left-2 transition group-hover:translate-y-5 translate-y-0 opacity-0 invisible group-hover:opacity-100 group-hover:visible duration-500 ease-in-out group-hover:transform z-50 min-w-[230px]">
                                    <div className="relative top-6 p-6 bg-[#ffffff] rounded-xl shadow-xl w-full ">
                                        <div
                                            className="w-10 h-10 bg-[#ffffff] transform rotate-45 absolute top-0 z-0 -translate-x-4 transition-transform group-hover:translate-x-3 duration-500 ease-in-out rounded-sm">
                                        </div>
                                        <div className="relative z-10">
                                            {Loading ? <div className='text-center'><PropagateLoader color={'#f50538'} loading={Loading} size={10} /></div>
                                            :
                                            <ul className="mt-3 text-[15px]">
                                                {collections.map((collection, index) => (
                                                    <li key={index}>
                                                        <button
                                                            className="bg-transparent text-[#050505] text-start hover:text-[#f50538]  py-2 block px-3"
                                                            onClick={() => handleWinerySelect(collection)}>
                                                            {renameWinery(collection.name)}
                                                        </button>
                                                    </li>
                                                ))}
                                            </ul>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="relative group px-3 py-2">
                                <p className="hover:opacity-70 hover:text-[#050505] cursor-poiter text-xl">
                                <a href="/about">   About </a>
                                </p>
                            </div>
                            <div>
                                {user ? (
                                    <Link className='hover:opacity-70 hover:text-[#050505] cursor-poiter text-xl ml-7' to='/favorites'>Notes</Link>
                                    ) : (
                                    <Link className='hover:opacity-70 hover:text-[#050505] cursor-poiter text-xl ml-7' to='/login'>Notes</Link>
                                )}
                            </div>
                            <div className="relative  items-center ">
                                <SearchBar />
                            </div>
                        </div>
                    </div>
                    <div className="relative group px-3 py-2 sm:relative  ">
                        <div className="flex items-center ">
                            <motion.img src={user ? user.photoURL : Avatar} whileHover={{ scale: 1.1 }}
                                className='w-10 rounded-full' alt='avatar'
                            />
                        </div>
                        {showPopup && location.pathname === '/' && (
                            <div className="absolute top-full right-5 p-2 flex items-center justify-start w-auto h-16 bg-[#ffffff] text-[#050505] text-sm whitespace-nowrap rounded-xl shadow-xl ">
                                <p >Welcome back, {userName}!</p>
                            </div>
                        )}
                        <div className="absolute top-5 -left-6 transition group-hover:translate-y-5 translate-y-0 opacity-0 invisible group-hover:opacity-100 group-hover:visible duration-500 ease-in-out group-hover:transform z-50 min-w-[120px] transform">
                            <div className="relative top-6 p-4 bg-[#ffffff] rounded-xl shadow-xl w-full">
                                <div
                                    className="w-10 h-10 bg-transparent transform rotate-45 absolute top-0 z-0 -translate-x-4 transition-transform group-hover:translate-x-3 duration-500 ease-in-out rounded-sm">
                                </div>
                                <div className="relative z-auto">
                                    <ul className="text-[15px]">
                                        <li>
                                            {!user && (
                                                <p className="bg-transparent text-[#050505] hover:text-[#f50538] text-lg p-1 block py-1  font-semibold cursor-pointer" >
                                                    <Link to='/login'>Log In</Link>
                                                </p>
                                            )}
                                        </li>
                                        <ul>
                                            {user && (
                                                <li>
                                                    <p className="bg-transparent text-[#050505] hover:text-[#f50538] text-lg p-1 block py-1 font-semibold cursor-pointer" onClick={logOut}>
                                                        Log Out
                                                    </p>
                                                </li>
                                            )}
                                        </ul>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="relative cursor-pointer" onClick={handleCartClick}>
                        <UilShoppingCart size="35" />
                        <div className="absolute -top-4 -right-4 w-7 h-7 bg-red-700 rounded-full text-[#ffffff] text-center text-zm ">
                            {totalQuantity}
                        </div>
                        {isCartOpen && (
                            <>
                                <div className="fixed top-0 left-0 w-full h-full bg-black opacity-50" onClick={() => setIsCartOpen(false)}></div>
                                <div className="absolute top-12 right-0 p-2 bg-[#ffffff] rounded-xl shadow-xl w-64 h-auto">
                                    <div
                                        className="w-10 h-10 bg-[#ffffff] transform rotate-45 absolute top-0 right-2 z-0 transition-transform group-hover:translate-x-3 duration-500 ease-in-out rounded-sm">
                                    </div>
                                    {cart.map(item => (
                                        <div key={item.id}>
                                            <span className=' ml-6'>Your shopping cart</span>
                                            <hr />
                                            <span>{item.name}</span>
                                            <span>Total Price: {item.quantity * item.price}</span>
                                            <hr />
                                            <button onClick={() => handleRemoveFromCart(item.id)}>Remove</button>
                                        </div>
                                    ))}
                                    <div>Total Cart Price: {cart.reduce((total, item) => total + item.quantity * item.price, 0)}</div>

                                </div>
                            </>
                        )}
                    </div> */}
                </div>
            </div>

            {/* this is the navbar component for a mobile view */}
            <div className="flex items-center justify-between md:hidden w-full h-full m-1 mr-5 ">
                <div className="relative">
                    {isOpen ?
                        <UilTimes className='text-4xl ml-2 mt-2' size={40} onClick={() => setIsOpen(!isOpen)} /> :
                        <UilBars className='text-4xl ml-2 mt-2' size={40} onClick={() => setIsOpen(!isOpen)} />
                    }
                    {showPopup && location.pathname === '/' && (
                        <div className="absolute mt-7 left-36 bg-[#ffffff] text-[#050505] flex items-center p-2 w-auto h-12 text-md whitespace-nowrap rounded-lg shadow-xl ">
                            <p >Welcome back, {userName}!</p>
                        </div>
                    )}
                </div>
                <div className=" md:hidden flex w-full h-full items-center justify-center">
                    <Link to={'/'}>
                    <img src={DarklogoNew} alt="logo" className='logo hover:opacity-90' />
                    </Link>
                </div>
                <div className="flex items-center gap-1 mr-8">
                    <motion.img src={user ? user.photoURL : Avatar} whileHover={{ scale: 1.1 }}
                        className='w-12 rounded-full' alt='avatar' />
                </div>
                {
                    isOpen && (
                        <div ref={menuRef} className="w-full bg-[#ffffff] shadow-xl rounded-lg flex flex-col absolute mt-3 right-0 px-4 py-2 top-24">
                            <div className='flex flex-col px-0 py-2  gap-1'>
                                <div className="flex justify-center items-center ">
                                    <SearchBar />
                                </div>
                                <a href='/home' className="text-[#050505]  hover:text-[#f50538] px-3 py-2 rounded-md text-xl font-medium">Home</a>
                                {/* // this the dropdown menu for the wines mobile version */}
                                <div ref={dropDownRef} className='flex flex-col '>
                                    <div className='flex justify-between text-[#050505]  hover:text-[#f50538] px-3 py-2 rounded-md text-xl font-medium '>
                                        <p onClick={() => setDropDown(!dropDown)}>Wineries</p>
                                        <UilAngleDown className={dropDown ? 'transform rotate-180' : ''} onClick={() => setDropDown(!dropDown)} />
                                    </div>
                                    {dropDown && (
                                        <div className="  rounded-xl shadow-lg transition-all duration-500 ease-in-out overflow-y-auto lg:w-1/2 lg:h-auto lg:overflow-visible">
                                            <div className="relative z-10">
                                                <ul className="mt-3 text-sm lg:text-base">
                                                    {collections.map((collection, index) => (
                                                        <li key={index}>
                                                            <button
                                                                className="bg-transparent text-[#050505] text-start hover:text-[#f50538]  py-2 block px-3"
                                                                onClick={() => handleWinerySelect(collection)}>
                                                                {renameWinery(collection.name)}
                                                            </button>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    )
                                    }
                                </div>
                                <a href='/about' className="text-[#050505]  hover:text-[#f50538] px-3 py-2 rounded-md text-xl font-medium">About</a>
                                {user ? (
                                    <a href='/favorites' className="text-[#050505]  hover:text-[#f50538] px-3 py-2 rounded-md text-xl font-medium" >Notes</a>
                                ) : (
                                    <a href='/login' className="text-[#050505]  hover:text-[#f50538] px-3 py-2 rounded-md text-xl font-medium" >Notes</a>
                                )}
                                {!user && (
                                    <a href='/login' className='text-[#050505]  hover:text-[#f50538] px-3 py-2 rounded-md text-xl font-medium ' >Log In</a>
                                )}
                                {user && (
                                    <p className="bg-transparent text-[#050505] hover:text-[#f50538]  px-3 py-2 rounded-md text-xl font-medium " onClick={logOut}>
                                        Log Out
                                    </p>
                                )}
                            </div>
                        </div>
                    )
                }
            </div>
        </div>

    );

}


export default Navbar;