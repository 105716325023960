// ? This component is the footer of the page, it contains the social media links and the copy right of the page
import React from 'react'
import { motion } from 'framer-motion'
import { UilFacebookF, UilTwitter, UilInstagram, UilMessage } from '@iconscout/react-unicons'


export const FooterPage = () => {
    return (
        <div>
            <div className="flex items-center justify-center mt-5">
                <hr className="w-1/4 border-1 border-[#f50538]" />
                <span className="px-2 text-[#050505] font-semibold">CONNECT WITH US</span>
                <hr className="w-1/4 border-1 border-[#f50538]" />
            </div>
            <div className='flex items-center justify-center mt-3   gap-8'>
                <a href='https://www.facebook.com/iamsommapp/' target='_blank' rel='noopener noreferrer'>
                    <motion.p
                        whileHover={{ scale: 2.1 }}
                        whileTap={{ scale: 0.9 }}
                        className=''> <UilFacebookF color="#00000" /></motion.p>
                </a>
                <a href='https://twitter.com/iamsommapp/' target='_blank' rel='noopener noreferrer'>
                    <motion.p
                        whileHover={{ scale: 2.1 }}
                        whileTap={{ scale: 0.9 }}
                        className=''> <UilTwitter color="#00000" /></motion.p>
                </a>
                <a href='https://www.instagram.com/iamsommapp/' target='_blank' rel='noopener noreferrer'>
                    <motion.p
                        whileHover={{ scale: 2.1 }}
                        whileTap={{ scale: 0.9 }}
                        className=' '> <UilInstagram color="#00000" /></motion.p>
                </a>
                <a href="mailto:shivani@iamsomm.com">
                    <motion.p
                        whileHover={{ scale: 2.1 }}
                        whileTap={{ scale: 0.9 }}
                        className=' '> <UilMessage color="#00000" /></motion.p>
                </a>
            </div>
            <p className="text-sm text-center text-[#f50538] my-6 mt-4 ">ⓒ Copyright 2024 IAmSOMM</p>
            <p className="text-sm text-center text-[#00000] my-6 mt-4 ">
                <a href='/TermsAndConditions'> Terms and conditions</a> |
                <a href='https://www.freeprivacypolicy.com/live/92cff72e-c23a-42fc-a784-8801278382ad' target='_blank' rel='noopener noreferrer'> Privacy Policy</a> 

            </p>
        </div>
    )
}

export default FooterPage