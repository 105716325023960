//? Home page view that will show the user how to taste wine like a professional. 
//? It will also display a brief description of the app and its purpose.
// ? style winth tailwindcss and framer-motion for animation.
import React, { useState, useEffect } from 'react'
import Home_Image from '../assets/icons/home_Image.jpg'
import MVPWineries from '../assets/images/mvpWineries.png';
import FooterPage from '../components/FooterPage'
import ChatPopup from '../components/ChatPopup';
import BackToTopButtom from '../components/BackToTopButtom';
import ClipLoader from "react-spinners/ClipLoader";
import { getAnalytics, logEvent } from "firebase/analytics";

const analytics = getAnalytics();

logEvent(analytics, 'page_view', {
      page_title: 'Home'
  });

const Home = () => {
      const [loading, setLoading] = useState(true);

      useEffect(() => {
            setLoading(true);
            setTimeout(() => {
                  setLoading(false);
            }, 2000);
      }, []);

      return <div className=" rounded-md">
            {loading ? <div className="flex justify-center items-center h-screen">
                  <ClipLoader color={"#f84444"} loading={loading} size={60} /></div> :
                  <div className="max-w-md mx-auto bg-transparent rounded-xl  overflow-hidden md:max-w-7xl mt-1">
                        <div className="mt:flex">
                              <div>
                                    <div className="first-letter:text-4xl first-letter:font-bold 
                                    uppercase tracking-widest text-2xl text-[#050505] text-center font-semibold md:mt-3 mb-9 py-3  ">Taste what you like!
                                    </div>
                                    <img className="h-60 w-full object-cover md:h-full md:w-468" src={Home_Image} alt="background" />
                              </div>
                              <div className="p-10 text-center md:text-start">
                                    <div className="mt-3 text-[#050505] first-letter:text-xl text font-serif text-base/loose">
                                          Welcome to IAmSomm, where you are your own sommelier and you drink what you like!
                                          
                                          We are here to introduce you to Washington wines and help you learn about them while you taste.
                                          On this site, you will find a list of wineries in Woodinville, WA along with their tasting menus and detailed tasting notes.
                                          Create an account to save notes for each wine as you taste them or add them to your favorites to remember them for later.
                                          <br /> <br/>
                                          As you begin your wine tasting journey, you might be wondering how to get started with wine
                                          tasting. 
                                          Look, smell, taste - starting with your basic senses and expanding from there you will learn how to taste wines like the pros in no time.
                                          <br /><br />
                                          Steps to taste wine like a professional:
                                          <ol>
                                                <li>1. <b>Look:</b> Examine the wine's color, clarity, and opacity. Identify specific shades for red or white wines. Check for sediment or floaters by tilting and swirling the glass.</li>
                                                <li>2. <b>Smell:</b> Swirl the wine to release aromas, then take a quick sniff for initial impressions. Dive deeper by inhaling deeply, identifying scents like oak, berry, flowers, vanilla, or citrus. Let the aromas mix by swirling again.</li>
                                                <li>3. <b>Taste:</b> Take a small sip and let it roll around your mouth, experiencing the Attack phase (initial palate impression). Assess alcohol content, tannin levels, acidity, and residual sugar for balance. Note sensations like intensity, complexity, and texture.</li>
                                          </ol>
                                          <br/>
                                          Now that you are armed with the basics, let's raise a glass you and your wine tasting journey! Cheers!
                                    </div>
                              </div>
                              <img className="h-60 w-full object-cover md:h-full md:w-468" src={MVPWineries} alt="background" />
                              <figcaption className="text-center"><i> Our Partner Wineries in Woodinville, WA</i></figcaption>
                              <ChatPopup />
                              <FooterPage />
                        </div>
                  </div>
            }
            <BackToTopButtom />
      </div>
};

export default Home;