// ? This component is responsible for displaying the wine type page.
// ? It will display the wine image, name, and details of the selected wine type.
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import wineImage from '../utils/wineImage';
import { FooterPage } from '../components/FooterPage';
import BackToTopButtom from '../components/BackToTopButtom';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from "../firestore";
import SearchBar from '../components/SearchBar';


function WineTypePage() {
    const { wineType } = useParams();
    const [wine, setWine] = useState(null);

    useEffect(() => {
        const getWines = async () => {
            const q = query(collection(db, 'seattleMetroWineAreas/woodinvilleDowntownDistrict/tsillanCellars'), where('wineType', '==', wineType));
            const querySnapshot = await getDocs(q);
            setWine(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
        };
        getWines();
    }, [wineType]);


    return (
        <div className="max-w-md mx-auto rounded-lg shadow-lg overflow-hidden md:max-w-7xl mt-10 overlay">
            <div className='py-6 px-5 items-start justify-center '>
                <h1 className='text-center text-2xl font-bold text-[#f84444] tracking-wide'>Taste what you like!!</h1>
                <div className=" md:p-2 md:ml-4 md:flex-items-center  flex items-center flex-wrap">
                    <div className="md:hidden flex items-center ">
                        <div className="relative">
                            <SearchBar />
                        </div>
                    </div>
                </div>
                {wine && wine.map(wine =>
                    <div key={wine.id} className="max-w-md mx-auto border border-black rounded-xl shadow-md overflow-hidden md:max-w-6xl m-5 ">
                        <div className="md:flex">
                            <div className="md:shrink-0">
                            <img src={wineImage[wine.id] || wineImage.default[wine.wineType.toLowerCase()]} alt={wine.wineName}
                                className={` ${wine.wineryName === 'Ancestry' || wine.wineryName === 'Baer Winery' || wine.wineryName === 'Rooted Souls' ? 'label' :  'bottle'}`}
                            />
                            </div>
                            <div className="p-8">
                                <div className=" text first-letter:uppercase tracking-wide text-xl text-[#f50538] font-bold">{wine.wineName || 'No Available'}</div>
                                <p className="mt-2 text-[#050505]font-sans font-semibold md:text-start">Current Release</p>
                                <p className={`mt-2 font-mono md:text-start ${wine.currentRelease ? 'text-[#bbd700]' : 'text-[#f50538]'}`}>
                                    {wine.currentRelease ? 'Released' : 'NO Release'}
                                </p>
                                <p className="mt-2 text-[#f21505] font-bold md:text-start">Tasting Notes:</p>
                                <p className="mt-2 text-[#050505] font-bold md:text-start">{wine.tastingNotes}</p>
                                <p className="mt-2 text-[#050505]font-bold md:text-start">Productions Notes</p>
                                <p className="mt-2 text-[#050505]  md:text-start font-sanref"> Barrel Regimen : ({wine.barrelRegimen}) : {wine.barrelMakeup}</p>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <hr className='border border-[#050505]' />
            <FooterPage />
            <BackToTopButtom />
        </div>
    );
}

export default WineTypePage;