import React, { useEffect, useState } from 'react';

export const LikeContext = React.createContext();

export const LikeProvider = ({ children }) => {
    const [likedWines, setLikedWines] = useState(
        JSON.parse(localStorage.getItem('likedWines')) || []
    );

    const [likedWineries, setLikedWineries] = useState(
        JSON.parse(localStorage.getItem('likedWineries')) || []
    );

    const [selectedWinery, setSelectedWinery] = useState(null);

    useEffect(() => {
        localStorage.setItem('likedWines', JSON.stringify(likedWines));
        localStorage.setItem('likedWineries', JSON.stringify(likedWineries));
    }, [likedWines, likedWineries]);

    const addLike = (wine) => {
        setLikedWines([...likedWines, wine]);
    };

    const removeLike = (wineId) => {
        setLikedWines(prevWines => prevWines.filter(wine => wine.id !== wineId));
    };

    const addWineryLike = (winery) => {
        setLikedWineries(prevLikedWineries => [...prevLikedWineries, winery]);
    };

    const removeWineryLike = (wineryId) => {
        setLikedWineries(prevLikedWineries => {
            const newLikedWineries = prevLikedWineries.filter(winery => winery.id !== wineryId);
            return newLikedWineries;
        });
    };

    return (
        <LikeContext.Provider value={{ likedWines, addLike, removeLike, likedWineries, addWineryLike, removeWineryLike, selectedWinery, setSelectedWinery }}>
            {children}
        </LikeContext.Provider>
    );
};